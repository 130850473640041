import React, {useState, Fragment} from 'react';   
import Warning from '../../../assets/images/warning.png'
import classes from './InputRetAgeWarning.module.css'
import InfoModal from '../../general/InfoModal'
import { ageToYearsAndMonths } from '../../../shared/utilities/Formatters';

const InputRetAgeWarning = (props) => {
    
    const [show_modal, set_show_modal] = useState(false)
    const ret_date = new Date(props.ret_date)
    var be_invisible
    
    if(props.spa_years && props.ret_date) {
        const legacy_scheme = props.scheme
        const legacy_npa = props.legacy_npa
        const legacy_npa_date = props.legacy_npa_date
        const reformed_npa_date = props.reformed_npa_date
        const is_2008 = legacy_scheme === '2008 Section'
        const formattedReformNPA = ageToYearsAndMonths(props.reformedNPA)
        
        be_invisible = (props.ret_date === undefined) || (ret_date >= reformed_npa_date) || props.invisible
        const pre_2015_only = legacy_npa_date <= ret_date 

        var message 
        var modal_message

        if(pre_2015_only) {
            message = "WARNING: The retirement date you have chosen is earlier than your Normal Pension Age for the 2015 Scheme. This means your pension benefits will be reduced for early payment, because you’ll receive them for longer. "
            modal_message = <Fragment>
            <h1> Implications of early retirement </h1>
            <p>Each part of the Scheme has a different Normal Pension Age (NPA).</p>

            <p>Your NPA for your {legacy_scheme} is {legacy_npa}.</p>

            <p>Your NPA for your 2015 Scheme is the same as your state pension age, which is {formattedReformNPA}.</p>

            <p>Any benefits you take before your NPA will be reduced because you will receive them for longer.</p>

            <p>You don’t have to take all your benefits at the same time. On your detailed results page you can see how your retirement age might affect the value of your benefits.</p>

            </Fragment>

            

        } else  {
            message = 'WARNING: The retirement date you have chosen is earlier than your Normal Pension Ages for both the ' + legacy_scheme + ' and the 2015 Scheme. This means your pension benefits will be reduced for early payment, because you’ll receive them for longer. '
            modal_message = <Fragment>
            <h1> Implications of early retirement </h1>
            <p>Each part of the Scheme has a different Normal Pension Age (NPA).</p>

            <p>Your NPA for your {legacy_scheme} is {legacy_npa}.</p>

            <p>Your NPA for your 2015 Scheme is the same as your state pension age, which is {formattedReformNPA}.</p>

            <p>Any benefits you take before your NPA will be reduced because you will receive them for longer.</p>

            <p>You don’t have to take all your benefits at the same time. On your detailed results page you can see how your retirement age might affect the value of your benefits.</p>

            </Fragment>
        } 

    } else {
        be_invisible = true
    }
    



    return(
        <div className = {classes.main_container + ' ' + (be_invisible ? classes.invisible : '')} > 
            <div className = {classes.sub_container} onClick = {() => { set_show_modal(true)}}> 
                <div className = {classes.img_container}> 
                    <img src = {Warning} alt = 'Warning symbol'/>
                </div>
                <p> {message}  </p>
            </div>
            {show_modal && <InfoModal
            infoMessage = {modal_message}
            modalHandler = {() => { set_show_modal(false)}}
            />}
        </div>
    )
}

export default InputRetAgeWarning