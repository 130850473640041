import generateSalaryData from "../utilities/InterpolateSalaries";
import { getFinancialYear } from "../utilities/InterpolateSalaries";

export const submitInputData = (inputs) => {
  // API endpoint
  let address = (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) + "/api/get_results";

  const service_days = (inputs.serviceDays === undefined || inputs.serviceDays === 'xxx' || inputs.serviceDays === '') ? 0 : inputs.serviceDays;
  const service_years = (inputs.serviceYears === undefined || inputs.serviceYears === 'xxx' || inputs.serviceYears === '') ? 0 : inputs.serviceYears;
  const service_from_bs = inputs.BenefitStatement === "Yes" ? (service_years + service_days / 365) : 0;

  var ret_date;
  var ret_age;
  if (inputs.coming_from === 'ret_switch') {
    // If the API was triggered by the retirement age switch on the results page
    ret_age = inputs.retirementAge;
    ret_date = 'NA';
  } else {
    ret_date = inputs.retirementDate;
    ret_age = 'NA';
  }

  const financialYear = getFinancialYear()
  console.log('Main API: inputs.extra_salary_data', inputs.extra_salary_data)
  console.log('Main API: inputs.salaryIncrease', inputs.salaryIncrease)
  var extra_salary_data
  if(inputs.salary2015 === 'Input Salary Information') {
    extra_salary_data = generateSalaryData(inputs.extra_salary_data, 2015, financialYear, inputs.salaryIncrease / 100 + 1, true)
  } else {
    extra_salary_data = generateSalaryData([{'year': financialYear, 'salary': inputs.salaryCurrent, 'basis': 'provided'}], 2015, financialYear, inputs.salaryIncrease / 100 + 1, true)
  }

  console.log('extra_salary_data in mainAPI, including non-remedy', extra_salary_data)

  const InputData = {
    DJS: inputs.dateOfJoining,
    DOB: inputs.dateOfBirth,
    HasPT: inputs.hasPT,
    pt: inputs.partTimeService,
    retirement_age: ret_age,
    retirement_date: ret_date,
    salary_today: inputs.salaryCurrent,
    salary_2015: inputs.salary2015,
    salary_increase: inputs.salaryIncrease / 100,
    service_from_benefit_statement: service_from_bs,
    equalise: inputs.equalise,
    commutation: inputs.commutation,
    inflation: inputs.inflation,
    extra_salary_data : extra_salary_data,
    status: inputs.legacyScheme === '2008 Section' ? 'Neither of these' : inputs.status,
    "selected_CP_lump_sum_choose_care": inputs['selected_CP_lump_sum_choose_care'],
    "selected_CP_lump_sum_choose_legacy": inputs['selected_CP_lump_sum_choose_legacy'],
    "selected_LP_lump_sum_choose_care": inputs['selected_LP_lump_sum_choose_care'],
    "selected_LP_lump_sum_choose_legacy": inputs['selected_LP_lump_sum_choose_legacy']
  };

  console.log(InputData)

  // Request options for fetch
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(InputData),
  };

  // Perform the API request
  return fetch(address, requestOptions)
    .then(response => response.json())
    .then(from_backend => {
      const ret = {
        'pension' :   {
          'choose_legacy' : {
            'old' : from_backend['LP choose legacy'], 
            'new' : from_backend['CP choose legacy']
          } ,
          'choose_reformed' : {
            'old' : from_backend['LP choose care'], 
            'new' : from_backend['CP choose care']
          } 
        }, 
        'lump sum' : {
          'choose_legacy' : from_backend['LS choose legacy'], 
          'choose_reformed' : from_backend['LS choose care']
        }, 
        'exact_age' : from_backend['exact_age'], 
        'possible_to_equalise' : from_backend['possible_to_equalise'],
        'inflation' : inputs.inflation, 
        'retirementAge' : from_backend['ret_age'], //could be a float based on difference between DOB and member's retirement date
        'retirementDate' : from_backend['ret_date'] ,
        'salaryIncrease' : inputs.salaryIncrease, 
        'equalise' : inputs.equalise, 
        'ERF care': from_backend['ERF care'],
        'ERF legacy': from_backend['ERF legacy'],
        'ERF legacy ls': from_backend['ERF legacy ls'],
        'reformed_npa' : from_backend['reformed npa'], 
        'legacy_npa' : from_backend['legacy npa'],
        'spa': from_backend["SPA"],
        'spa_age' : from_backend['SPA_age'],
        'reformed_npa_date' : from_backend['reformed_npa_date'],
        'DOB' :from_backend['DOB'],
        'accrual_rate' : from_backend['accrual_rate'],
        'salary_at_retirement' : from_backend['salary_at_retirement'],
        'used_service_choose_legacy' : from_backend['used_service_choose_legacy'],
        'used_service_choose_reformed' : from_backend['used_service_choose_reformed'],
        'possible_to_equalise' : from_backend['possible_to_equalise'],
        "max_care_lump_sum_choose_care": from_backend['max_care_lump_sum_choose_care'],
        "max_legacy_lump_sum_choose_care": from_backend['max_legacy_lump_sum_choose_care'],
        "max_care_lump_sum_choose_legacy": from_backend['max_care_lump_sum_choose_legacy'],
        "max_legacy_lump_sum_choose_legacy": from_backend['max_legacy_lump_sum_choose_legacy'],
        "min_care_lump_sum_choose_care": from_backend['min_care_lump_sum_choose_care'],
        "min_legacy_lump_sum_choose_care": from_backend['min_legacy_lump_sum_choose_care'],
        "min_care_lump_sum_choose_legacy": from_backend['min_care_lump_sum_choose_legacy'],
        "min_legacy_lump_sum_choose_legacy": from_backend['min_legacy_lump_sum_choose_legacy'],
        "max_care_lump_sum_choose_care_pretend": from_backend['max_care_lump_sum_choose_care_pretend'],
        "max_legacy_lump_sum_choose_care_pretend": from_backend['max_legacy_lump_sum_choose_care_pretend'],
        "max_care_lump_sum_choose_legacy_pretend": from_backend['max_care_lump_sum_choose_legacy_pretend'],
        "max_legacy_lump_sum_choose_legacy_pretend": from_backend['max_legacy_lump_sum_choose_legacy_pretend'],
        "min_care_lump_sum_choose_care_pretend": from_backend['min_care_lump_sum_choose_care_pretend'],
        "min_legacy_lump_sum_choose_care_pretend": from_backend['min_legacy_lump_sum_choose_care_pretend'],
        "min_care_lump_sum_choose_legacy_pretend": from_backend['min_care_lump_sum_choose_legacy_pretend'],
        "min_legacy_lump_sum_choose_legacy_pretend": from_backend['min_legacy_lump_sum_choose_legacy_pretend'],
        "selected_CP_lump_sum_choose_care": from_backend['selected_CP_lump_sum_choose_care'],
        "selected_CP_lump_sum_choose_legacy": from_backend['selected_CP_lump_sum_choose_legacy'],
        "selected_LP_lump_sum_choose_care": from_backend['selected_LP_lump_sum_choose_care'],
        "selected_LP_lump_sum_choose_legacy": from_backend['selected_LP_lump_sum_choose_legacy'],
        'tax_threshold_exceeded_care' : from_backend['tax_threshold_exceeded_care'],
        'tax_threshold_exceeded_legacy' : from_backend['tax_threshold_exceeded_legacy'],
        'choose reformed check' : from_backend['choose reformed check'],
        'choose legacy check': from_backend['choose legacy check'],
        did_adjust: from_backend.did_adjust,
        total_service: from_backend.total_service,
        service_age_65_to_end_legacy: from_backend.service_age_65_to_end_legacy,
        frac: from_backend.frac,
        adjusted_ls_lrf: from_backend.adjusted_ls_lrf,
        adjusted_pen_lrf: from_backend.adjusted_pen_lrf,
        adjuster: from_backend.adjuster,
        pre_adjusted_pen_ret_factor: from_backend.pre_adjusted_pen_ret_factor,
        pre_adjusted_ls_ret_factor: from_backend.pre_adjusted_ls_ret_factor
      }
      return ret
    })
    .catch(error => {
      console.error('Error occurred:', error);
      throw error;  // Rethrow the error to be handled by the caller
    });
};