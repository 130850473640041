import React, { Fragment } from "react";
import classes from './5-SimpleView.module.css'
import { useNavigate } from "react-router-dom";
import ResultsTable from "../components/simple_view/ResultsTable";

const SimpleResults = (props) => {


  let navigate = useNavigate();

  return (
    <Fragment>
     
          <ResultsTable></ResultsTable>
          <div className={classes.buttons_container}> 
          <button
            onClick ={ () => {navigate('/show-detail')} }
          > Show me the detail
          </button>

      </div>
    </Fragment>
  );
};

export default SimpleResults