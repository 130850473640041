import classes from './MiniSlider.module.css'
import React, {useState, useRef, useEffect, useContext} from 'react'
import { textToPercent,processPercentDelete, sanitisePercentString } from '../../../utilities/PercentageHandlingFunctions'
import { submitInputData } from '../../../shared/apis/mainAPI'
import { InputDataContext } from '../../../shared/contexts/InputDataContext'
import { currencyFormatter } from '../../../utilities/Formatters'

const MiniSlider = (props) => {
    const [value, set_value] = useState(props.selected)
    const [text_value, set_text_value] =useState(String(value))
    const [mouseDown, setMouseDown] = useState(false)
    const [text_focused, set_text_focused] = useState(false)
    const initial_mount = useRef(true)
    const { inputsRef} = useContext(InputDataContext);

    const make_value_suitable_for_slider = (x) => {
      if(Number(x) === Number(props.max)) {
        return props.pretend_max
      } else if(Number(x) === Number(props.min)) {
        return props.pretend_min
      } else {
        return Number(x)
      }
    }



    const handleChange = (event) => {
      let new_val = Number(event.target.value)
      new_val = Math.min(new_val, Number(props.max))
      new_val = Math.max(new_val, Number(props.min))
        set_value(new_val)
        setMouseDown(true)
    }

    function clean_lump_sum_string(str) {
      return str.replace(/[^0-9]/g, "")
    }

    function textChangeHandler(event) {
        set_text_value(clean_lump_sum_string(event.target.value))
      }

    useEffect(() => {
      if(!text_focused && !mouseDown){
        set_text_value(value)
      }
      
      if (!initial_mount.current && !mouseDown && Number(value) !== props.selected) {
        //if value does equal props.selected, it would indicate that the value the user has chosen is
        //no different to what the backend has already used in the last calculation, so no need
        //to get the backend to redo that same calculation. Or, it could mean that the backend just
        //changed `selected` (e.g. as a result of hte user pressing `Equal`), and that the value was just adjusted
        //to equal what was already just used in the backend's calculation - so again, no need to repeat the calculation 
        //with the same number
        const fetchData = async () => {
          inputsRef.current[props.name_for_backend] = value;
          try {
            const data = await submitInputData(
              {...inputsRef.current, [props.name_for_backend] : value,
              }
            );
            console.log('data received from backend', data)
            if(Number(data[props.name_for_backend]) !== Number(value)) {
              //in which case, the backend used a different lump sum to the 
              //one specified by the user. For example, perhaps it is equalising
              //lump sums, and in order to equalise, it reset the number supplied
              //by the user
              set_value(Number(data[props.name_for_backend]))
            }
            props.setOutputData(data);

          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      } else {
        initial_mount.current = false;
      }
    }, [value, mouseDown]);

    useEffect(
      () => {
        if(props.selected !== Number(value)) {
          set_value(props.selected)
          inputsRef.current[props.name_for_backend] = props.selected
        }
        if(Number(props.max) < value) {
          set_value(props.max)
          inputsRef.current[props.name_for_backend] = props.max
        }
        if(Number(props.min) > value) {
          set_value(props.min)
          inputsRef.current[props.name_for_backend] = props.min
        }
      },
      [props.max, props.min, props.selected]
    )
    
    return(
        <div className = {classes.main_container}>
            <h2> {props.title}</h2>
        <div className={classes.radio_container}>
      
        <input
          type="range"
          min= {String(props.pretend_min)}
          max= {String(props.pretend_max)}
          step="50"
          value={String(make_value_suitable_for_slider(value))}
          onChange={handleChange}
          className="slider"
          onMouseUp={() => {
            setMouseDown(false);
          }}
          onKeyUp={() => {
           setMouseDown(false);
          }}
        />
        <input type = 'text' 
        value = {
          text_focused ? 
          String(Math.round(Number(text_value))) :
          currencyFormatter(String(Math.round(Number(text_value))))
        } 
        onChange = {textChangeHandler}
        onFocus = {() => set_text_focused(true)}
        onBlur = {
          () => {
            set_text_focused(false)
            if(Number(value) !== Number(text_value)) {
              let new_val = Number(text_value)
              new_val = Math.max(new_val, props.min)
              new_val = Math.min(new_val,  props.max)
              set_value(Number(text_value))
              //If this triggers a useEffect, I hope that text_focused will be false within the useEffect
            }
          }
        }
        />
      </div>
      <div className = {classes.max_min_labels}>
          <p
          className = {classes.min}
          > {currencyFormatter(Math.round(props.min))} </p>
          <p
          className = {classes.max}
          > {currencyFormatter(Math.round(props.max))} </p>

      </div>
      
      </div>
    )
}

export default MiniSlider