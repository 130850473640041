import React from 'react'

const SalaryCheckingTable = (props) =>{
    const jsonData = JSON.parse(props.jsonData)

    const keys = Object.keys(jsonData.year_start); // Get keys from any column to determine the number of rows
    
    const rowData = keys.map(index => ({
        year_start: jsonData.year_start[index],
        year_end: jsonData.year_end[index],
        fte_salary: jsonData.fte_salary[index],
        actual_pay: jsonData.actual_pay[index],
        annual_care: jsonData.annual_care[index],
        cumulative_care: jsonData.cumulative_care[index],
        revaluation_applied: jsonData.revaluation_applied[index]
}));
    return(
    <table>
      <thead>
        <tr>
          <th>Start Year</th>
          <th>End Year</th>
          <th>FTE Salary</th>
          <th>Actual Pay</th>
          <th>Annual Care</th>
          <th>Cumulative Care</th>
          <th> Revaluation applied to Cumulative CARE of previous year</th>
        </tr>
      </thead>
      <tbody>
        {rowData.map((row, index) => (
          <tr key={index}>
            <td>{row.year_start}</td>
            <td>{row.year_end}</td>
            <td>{row.fte_salary.toLocaleString()}</td>
            <td>{row.actual_pay.toLocaleString()}</td>
            <td>{row.annual_care.toLocaleString()}</td>
            <td>{row.cumulative_care.toLocaleString()}</td>
            <td>{index === 0 ? 'NA' : row.revaluation_applied.toLocaleString()}</td>
          </tr>
        ))}
      </tbody>
    </table>
    )
}

export default SalaryCheckingTable