import classes from './RetirementAgeWarning.module.css'
import React, {useState, Fragment } from "react";
import Image from "../../assets/images/warning.png"
import BenignImage from "../../assets/images/lrf_warning.png"
import InfoModal from "../../components/general/InfoModal";
import { setToStartOfDay } from '../../utilities/Formatters';
import { ageToYearsAndMonths } from '../../shared/utilities/Formatters';

const RetirementAgeWarning = (props) => {

    const [showModal, setShowModal] = useState('')

    const spa_age = Math.max(props.spa_years + props.spa_months / 12, 65)

    const clean_spa_age = ageToYearsAndMonths(spa_age)

    const is_2008 = props.legacySchemeName === '2008 Section'

    const modalHandler = () => {setShowModal('')} 

    const warning_colours = {'--col1' : 'crimson', '--col2' : 'rgb(243, 178, 178)'}
    const benign_colours = {'--col1' : 'var(--deep-blue)', '--col2' : 'rgb(178, 178, 243)'}

    const retirementDate = setToStartOfDay(new Date(props.retirementDate))
    const legacy_npa_date = setToStartOfDay(props.legacy_npa_date)
    const reformed_npa_date = setToStartOfDay(props.reformed_npa_date)
    const legacySchemeName = props.legacySchemeName
    const retireBeforeLegacyNPA = legacy_npa_date > retirementDate
    const retireOnLegacyNPA = (legacy_npa_date.getTime() === retirementDate.getTime())
    const retireOnReformedNPA = reformed_npa_date.getTime() === retirementDate.getTime()
    const retireAfterReformedNPA = reformed_npa_date < retirementDate
    var legacyMessage = retireBeforeLegacyNPA ? 
    (legacySchemeName + ' benefits reduced due to early payment') :
    ((!is_2008) ? 
    (legacySchemeName + ' benefits not increased despite late payment') :
    (legacySchemeName + ' benefits increased due to late payment'))

    const infoMessage = {
    'LRF1995' : <Fragment> 
        <h2> Implications of late retirement </h2>
        <p> Each part of the Scheme has a different Normal Pension Age (NPA). </p>
        <p> Your NPA for your 1995 Section is {props.legacy_npa}</p>
        <p> Your NPA for your 2015 Scheme is the same as your state pension age, which is {clean_spa_age} </p>
        <p> Benefits you take after your NPA from the 2015 Scheme will be increased for late payment, as you’ll receive them for a shorter time. </p>
        <p> The 1995 Section doesn’t provide any increases for taking your benefits later 
            than your NPA, but you will have a final salary link applied. This means the 
            value of your 1995 Section benefits will be based on your final pensionable pay,
            providing you haven‘t had a break of more than 5 years. Once you have claimed your
            1995 Section benefits, the annual Pensions Increase will be applied to them.</p>
        <p> You don’t have to take all your benefits at the same time.  </p>
    </Fragment>,
    'LRF2008' : <Fragment> 
        <h2> Implications of late retirement </h2>
        <p> Each part of the Scheme has a different Normal Pension Age (NPA). </p>
        <p> Your NPA for your 2008 Section is {props.legacy_npa}</p>
        <p> Your NPA for your 2015 Scheme is the same as your state pension age, which is {clean_spa_age} </p>
        <p> Benefits you take after your NPA from the 2008 Section and 2015 Scheme will be increased for late payment, as you’ll receive them for a shorter time. </p>
        <p> You don’t have to take all your benefits at the same time.  </p>
    </Fragment>,
    'age' : <Fragment> 
        <h2> Implications of early retirement </h2>
        <p> Each part of the Scheme has a different Normal Pension Age (NPA). </p>
        <p> Your NPA for your {props.legacySchemeName} is {props.legacy_npa}</p>
        <p> Your NPA for your 2015 Scheme is the same as your state pension age, which is {clean_spa_age} </p>
        <p> Benefits you take after your NPA from the 2008 Section and 2015 Scheme will be increased for late payment, as you’ll receive them for a shorter time. </p>
        <p> You don’t have to take all your benefits at the same time.  </p>

    </Fragment>,
    'option_a_ls' : <Fragment> 
    <h2> Tax Threshold Exceeded </h2>
    <p> The nominal lump sum you would receive under Option A is more than the tax-free limit of £268,275.
        You may be liable to tax charges on the part of the lump sum that exceeds this threshold.
         </p>
    </Fragment>,
    'option_b_ls' : <Fragment> 
    <h2> Tax Threshold Exceeded </h2>
    <p> The nominal lump sum you would receive under Option B is more than the tax-free limit of £268,275.
        You may be liable to tax charges on the part of the lump sum that exceeds this threshold.
         </p>
    </Fragment>
    }
    return(
        <div className = {classes.retirement_age_warning}
        style = {
            {'maxHeight' : '1000px'}
        }
        >
            <h2> Pension and Tax Notifications</h2>
        <div className = {classes.warnings_container}> 
            
            <div className = {classes.first_warning + ' ' + 
                ((!retireOnReformedNPA) ? 
                    classes.visible : 
                    '')
                }> 
                <div className = {classes.image_container} 
                style = {retireAfterReformedNPA ? benign_colours : warning_colours}
                > 
                    
                    <img src = {retireAfterReformedNPA ? BenignImage : Image}/>
                    <button
                    onClick = {() => setShowModal(retireAfterReformedNPA ? (is_2008 ? 'LRF2008' : 'LRF1995') : 'age')}
                    > 
                    <span className = {classes.line}> </span>
                    2015 Scheme benefits {retireAfterReformedNPA ? 'increased due to late' : 'reduced due to early'} payment </button>
                </div>
            </div>

            <div
            className = {classes.second_warning + (!retireOnLegacyNPA ? ' ' + classes.visible : '')}
            > 
            <div className = {classes.image_container}
            style = {retireBeforeLegacyNPA ? warning_colours : benign_colours}
            > 
                    <img src = {retireBeforeLegacyNPA ? Image : BenignImage}/>
                    <button
                    
                    onClick = {() => setShowModal(
                        retireBeforeLegacyNPA ? 
                        'age' : 
                        (is_2008 ? 'LRF2008' : 'LRF1995')
                    )}
                    >
                    <span className = {classes.line}> </span> 
                    {legacyMessage}</button>
                </div>
            </div>

            <div
            className = {classes.third_warning + (props.tax_threshold_exceeded_legacy ? ' ' + classes.visible : '')}
            > 
                <div className = {classes.image_container}
                style = {warning_colours}
                > 
                    <img src = {Image}/>
                    <button
                    onClick = {() => setShowModal('option_a_ls')}
                    > 
                    <span className = {classes.line}> </span>
                    Lump sum tax threshold exceeded for Option A
                    </button>
                </div>
            </div>
            <div
            className = {classes.fourth_warning + (props.tax_threshold_exceeded_care ? ' ' + classes.visible : '')}
            > 
                <div className = {classes.image_container}
                style = {warning_colours}
                > 
                    <img src = {Image}/>
                    <button
                    onClick = {() => setShowModal('option_b_ls')}
                    >
                        <span className = {classes.line}> </span>
                         Lump sum tax threshold exceeded for Option B
                    </button>
                </div>
            </div>
        </div>
        {showModal && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessage[showModal]} />
      )}
        </div>
    )
}

export default RetirementAgeWarning