import React, {useState, Fragment} from 'react';   
import LRFWarningImg from '../../../assets/images/lrf_warning.png'
import classes from './InputRetAgeWarning.module.css'
import InfoModal from '../../general/InfoModal'
import { ageToYearsAndMonths } from '../../../shared/utilities/Formatters';

const LRFWarning = (props) => {

    const is_2008 = props.legacyScheme === '2008 Section'
    const formattedReformNPA = ageToYearsAndMonths(props.reformedNPA)
    var notification_message
    var modal_message
    if(props.afterSPA) {
        notification_message = <Fragment>
            <p> 
                Your retirement age is after your {props.legacyScheme} Normal Pension Age 
                and your 2015 Scheme Normal Pension Age. This means 
                your {is_2008 ? '2008 Section and 2015 Scheme' : '2015 Scheme'} benefits
                will be increased for late payment, as you’ll receive them for a shorter time.
                {is_2008 ? '' : "Your 1995 Section pension will not be increased for taking your benefits later than your NPA, but you will have a final salary link applied. The annual Pensions Increase will be applied to your benefits once you’ve claimed them."}
            </p>
           
        </Fragment>

        modal_message = <Fragment> 
            <h1> Implications of late Retirement </h1>
            <p> 
                Each part of the Scheme has a different normal pension age, or NPA.
            </p> <p>
                Your NPA for your {props.legacyScheme} is {props.legacyNPA}.
            </p> 
                <p> Your NPA for your 2015 Scheme is the same as your state pension age, which is {formattedReformNPA}</p>
            <p> 
                Benefits you take after your NPA from the {is_2008 ? '2008 Section and 2015 Scheme' : '2015 Scheme'} will be increased for late payment, as you'll receive them for a shorter time.
            </p>
            {!is_2008 && <p> 
                    The 1995 Section doesn’t provide any increases for taking your benefits later than your NPA, but you will have a final salary link applied. This means the value of your 1995 Section benefits will be based on your final pensionable pay, providing you haven't had a break of more than 5 years. Once you have claimed your 1995 Section benefits, the annual Pensions Increase will be applied to them.
            </p>}
            <p>
                You don’t have to take all your benefits at the same time. On your detailed results page you can see how your retirement age might affect the value of your benefits. 
            </p>
        </Fragment>


    } else {
        notification_message = <Fragment>
            <p> 
                Your retirement age is after your {props.legacyScheme} Normal Pension Age. 
                {
                is_2008 ? 
                ' This means your 2008 Section benefits will be increased for late payment, as you’ll receive them for a shorter time.' :
                'Your 1995 Section pension will not be increased for taking your benefits later than your NPA, but you will have a final salary link applied. The annual Pensions Increase will be applied to your benefits once you’ve claimed them.'
            }
            </p>
        </Fragment>

        modal_message = <Fragment> 
            <h1> Implications of late Retirement </h1>
            <p> 
                Each part of the Scheme has a different normal pension age, or NPA.
            </p> <p>
                Your NPA for your {props.legacyScheme} is {props.legacyNPA}.
            </p> 
                <p> Your NPA for your 2015 Scheme is the same as your state pension age, which is {formattedReformNPA}</p>
            {is_2008 && <p> 
                Benefits you take after your NPA from the 2008 Section will be increased for late payment, as you'll receive them for a shorter time.
            </p>}
            {!is_2008 && <p> 
                    The 1995 Section doesn’t provide any increases for taking your benefits later than your NPA, but you will have a final salary link applied. This means the value of your 1995 Section benefits will be based on your final pensionable pay, providing you haven't had a break of more than 5 years. Once you have claimed your 1995 Section benefits, the annual Pensions Increase will be applied to them.
            </p>}
            <p>
                You don’t have to take all your benefits at the same time. On your detailed results page you can see how your retirement age might affect the value of your benefits. 
            </p>
        </Fragment>
    }
    
    
    const [show_modal, set_show_modal] = useState(false)
    

    return(
        <div className = {classes.main_container + ' ' + (props.invisible  ? classes.invisible : '')} > 
            <div className = {classes.sub_container} onClick = {() => { set_show_modal(true)}}> 
                <div className = {classes.img_container}> 
                    <img src = {LRFWarningImg} alt = 'Late retirement factor warning symbol'/>
                </div>
                {notification_message}  
            </div>
            {show_modal && <InfoModal
            infoMessage = {modal_message}
            modalHandler = {() => { set_show_modal(false)}}
            />}
        </div>
    )
}

export default LRFWarning