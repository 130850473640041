import DraggableChart from '../components/SalaryDemo/SalaryChart'
import {useState, useEffect, useRef} from 'react'
import generateSalaryData from '../shared/utilities/InterpolateSalaries';
import classes from './SalaryDemo.module.css'
import Card from "../components/SalaryDemo/Card"
import Button from "../components/general/buttons/GenericButton"
import { getFinancialYear } from '../shared/utilities/InterpolateSalaries';
import SalarySlider from '../components/SalaryDemo/SalarySlider';
import { updateExplicitData } from '../shared/utilities/InterpolateSalaries';



const SalaryDemo = (props) => {
    const financialYear = getFinancialYear()
    const [SI, set_SI] = useState(props.salaryIncrease)
    console.log('SI in salary demo', SI)

    const resetData = [{'year' : financialYear, 'salary' : Number(props.salaryCurrent), 'basis' : 'provided'}]
    const resetDataAllYears = generateSalaryData(resetData, 2015, financialYear, SI)

    
    const initialData = props.inputsRef.current.stored_salary_data ? props.inputsRef.current.stored_salary_data : props.inputsRef.current.extra_salary_data

      const colour_mapper = {
        'provided' : 'rgb(0, 94, 184)',
        'interpolated' : 'hsl(0, 0%, 66%)',
        'extrapolated' : 'rgba(65,182,230, 30%)'
        }

    const colour_mapper_light = {
        'provided' : 'rgba(0, 94, 184, 0.7)',
        'interpolated' : 'hsla(0, 0%, 66%, 0.4)',
        'extrapolated' : 'rgba(65, 182, 230, 0.3)'
        }

    const name_mapper = {
        'provided' :'These are salary figures that you have specified.',
        'interpolated' : 'Salary values estimated based on the provided salaries in past and future years.',
        'extrapolated' : "Salary values estimated based on the provided salary in a future year and the assumed annual salary increase."
    }

    const shades={
        'a' : 'rgba(5,192,5,0.2)',
        'b' : 'rgba(192,5,5,0.2)'
    }
    
      
      const initialSalaryCurrentRef = useRef(props.inputsRef.current.salaryCurrent)
      const [explicitData, setExplicitData] = useState(initialData)
      const isExplicitDataProvided = explicitData.length > 1
      const initialChartData = generateSalaryData(initialData, 2015, financialYear, SI)
      const [chartData, setChartData] = useState(initialChartData);
      const presentData = chartData.filter((x) => x.year === financialYear)[0]
      if(presentData['salary'] !== props.inputsRef.current.salaryCurrent) {
        props.inputsRef.current.salaryCurrent = presentData['salary']
      }
      
      useEffect(
        () => {
            setChartData(
                generateSalaryData(explicitData, 2015, financialYear, SI)
            )
            props.inputsRef.current.salaryIncrease = ((SI - 1) * 100).toFixed(2)
        },
        [SI]
      )

        
      useEffect(
        () => {
            props.inputsRef.current.extra_salary_data = explicitData
            props.inputsRef.current.salary2015 = "Input Salary Information"
            props.inputsRef.current.stored_salary_data = null
            console.log("inputsRef in salary demo", props.inputsRef.current)
        },
        [explicitData]
      )  
    return(
        <div className = {classes.main_container}> 
            <h1> Historic Salary Information</h1>
            <p> On this page, you can provide a salary value for all years between 2015 and now.
            The years shown relate to financial years - for example, the salary in 2017 means your full time equivalent salary from 1 April 2017 to 31 March 2018.
            Drag the sliders, type in the boxes to the right of the sliders or drag-and-drop the points on the chart to change the salary values for different years.</p>
            <div className = {classes.chart_and_legend_container}> 
                <div className = {classes.chart_container}>
                    <DraggableChart
                    explicitData = {explicitData}
                    setExplicitData = {setExplicitData}
                    chartData = {chartData}
                    setChartData = {setChartData}
                    colour_mapper = {colour_mapper}
                    updateExplicitData = {updateExplicitData}
                    shades={shades}
                    />
                </div>
                <div className = {classes.legend_container}> 
                <div className = {classes.legend}> 
                {Object.entries(colour_mapper).map(([key, value], index) => {
                
                    return(
                        <div className = {classes.legend_row} key = {key}> 
                        <div className = {classes.legend_circle} style = {{'background' : value}}> </div> 
                        <p className = {classes.legend_text}> {name_mapper[key]} </p>
                    </div>
                    )
                    })
                }
                </div>
                <div className = {classes.salary_slider_container}> 
                <SalarySlider
                    title = {'Annual Salary Increase'}
                    max = {'5'}
                    min = {'0'}
                    selected = {((SI - 1) * 100).toFixed(1)}
                    set_SI = {set_SI}
                />
                </div>
                
                </div>
            </div>
            
            <div className = {classes.card_and_explanation_container} style = {{'background' : shades['a']}}> 
                <div className = {classes.card_container} >
                    {
                        chartData.map((this_row) => {
                            if(this_row.year < 2023) {
                                return(
                                    <Card
                                        key={this_row.year}
                                        selected={this_row.salary}
                                        max={200000}
                                        min={1}
                                        title={"Salary in " + this_row.year}
                                        onChange={(new_value) => {
                                            const newExplicitData = updateExplicitData(explicitData, this_row.year, new_value);
                                            const newChartData = generateSalaryData(newExplicitData, 2015, financialYear, SI);
                                            setChartData(newChartData);
                                            setExplicitData(newExplicitData);
                                        }}
                                        color = {colour_mapper[this_row.basis]}
                                        light_color = {colour_mapper_light[this_row.basis]}
                                        explicitData = {explicitData}
                                        this_year = {this_row.year}
                                        setExplicitData = {setExplicitData}
                                        setChartData = {setChartData}
                                        SI = {SI}
                                    />
                                )
                            }
                            
                        })
                    }
                </div>
                <div className = {classes.explanation}> 
                    <div className = {classes.explanation_inner}> 
                        <h3>Past salaries</h3>
                        <p>Please enter your <strong>full time equivalent salary</strong> in each financial year. The 2015 financial year ran from April 2015 to March 2016.</p>
                        <p>You do not need to enter salary information in each year, but you should provide as much information as possible to improve the illustration. Salaries not provided will be estimated based on other salaries and the annual salary increase assumption.</p>
                        <p>These figures can also be changed by dragging and dropping the points on the chart above.</p>
                    </div>
                </div>
            </div>
            <div className = {classes.card_and_explanation_container} style = {{'background' : shades['b']}}> 
                <div className = {classes.card_container} > 
                    
                    <Card
                        key={financialYear}
                        selected={presentData.salary}
                        max={200000}
                        min={1}
                        title={"Current salary (" + String(financialYear) + ")"}
                        onChange={(new_value) => {
                            const newExplicitData = updateExplicitData(explicitData, financialYear, new_value);
                            const newChartData = generateSalaryData(newExplicitData, 2015, financialYear, SI);
                            setChartData(newChartData);
                            setExplicitData(newExplicitData);
                        }}
                        color = {colour_mapper[presentData.basis]}
                        light_color = {colour_mapper_light[presentData.basis]}
                        explicitData = {explicitData}
                        this_year = {financialYear}
                        setExplicitData = {setExplicitData}
                        setChartData = {setChartData}
                        initialSalaryCurrentRef = {initialSalaryCurrentRef}
                        SI = {SI}
                    />
                </div>
                <div className = {classes.explanation}> 
                    <div className = {classes.explanation_inner}> 
                        <h3> Curent Salary </h3>
                        <p> This is the current full-time equivalent salary provided on the 'Your Details' page.
                            You can adjust this here.
                        </p>
                    </div>
                </div>
            </div>
            

            <div className = {classes.btn_container} >
                <Button
                    onClick = {(event) => {
                    setExplicitData(resetData); 
                    setChartData(resetDataAllYears)
                    }
                    }
                > Reset all 
                </Button>
                <Button onClick = {() => {
                    var salary2015
                    if(isExplicitDataProvided) {
                        salary2015 = "Input Salary Information"
                    } else {
                        salary2015 = "No, continue based on my current salary"
                    }
                    props.onClick(salary2015)
                }
                    }> {isExplicitDataProvided ? 'Close and use extra salary inputs' : "Close without extra salary inputs"} </Button>
            </div>

        </div>
    )
}

export default SalaryDemo 