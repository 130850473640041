import classes from './SalarySlider.module.css'
import React, {useState, useRef, useEffect, useContext} from 'react'

import { currencyFormatter } from '../../utilities/Formatters'
import { processPercentDelete, sanitisePercentString, textToPercent } from '../../utilities/PercentageHandlingFunctions'

const SalarySlider = (props) => {
    const [value, set_value] = useState(String(props.selected))
    const [text_value, set_text_value] =useState(String(value))
    const [mouseDown, setMouseDown] = useState(false)
    const [text_focused, set_text_focused] = useState(false)




    const generate_new_val = (event, old_val) => {    
      var new_val = processPercentDelete(event.target.value, old_val).replace(
        "%",
        ""
      );
    
      new_val = sanitisePercentString(new_val);
      
  
      const ends_with_decimal = new_val.charAt(new_val.length - 1) === '.'
      new_val = Math.min(Number(new_val), Number(props.max))
      new_val = Math.max(new_val, Number(props.min))
      new_val = String(Math.round(new_val*1000)/1000) + (ends_with_decimal ? '.' : '')
      return new_val
    }

    const handleChange = (event) => {
      const old_val = textToPercent(value);
      const new_val = generate_new_val(event, old_val)
      set_value(new_val)
      setMouseDown(true)
    }


    useEffect(() => {
      if(!text_focused && !mouseDown){
        set_text_value(value)
        props.set_SI(1 + value / 100)
      }
    }, [value, mouseDown]);

    useEffect(
      () => {
        if(props.selected !== Number(value)) {
          set_value(props.selected)
        }
        if(Number(props.max) < value) {
          set_value(props.max)
        }
        if(Number(props.min) > value) {
          set_value(props.min)
        }
      },
      [props.max, props.min, props.selected]
    )
    
    return(
        <div className = {classes.main_container}>
            <h2> {props.title}</h2>
        <div className={classes.radio_container}>
      
        <input
          type="range"
          min= {String(props.min)}
          max= {String(props.max)}
          step="0.1"
          value={String(value)}
          onChange={handleChange}
          className="slider"
          onMouseUp={() => {
            setMouseDown(false);
          }}
          onKeyUp={() => {
           setMouseDown(false);
          }}
        />
        <input type = 'text' 
        value = {
          text_value + '%'
        } 
        onChange = {e => set_text_value(generate_new_val(e, text_value +'%'))}
        onFocus = {() => set_text_focused(true)}
        onBlur = {
          () => {
            set_text_focused(false)
            set_value(Number(text_value))
          }
        }
        />
      </div>
     
      
      </div>
    )
}

export default SalarySlider