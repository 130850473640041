import React, { Fragment, useState, useContext } from "react";
import ReactDOM from "react-dom";
import classes from "./TermsModal.module.css";
import { deleteCookie } from "../../shared/utilities/Cookies";
import { InputDataContext } from "../../shared/contexts/InputDataContext";
import GenericButton from "../general/buttons/GenericButton";

const Backdrop = () => {
  return <div className={classes.background} />;
};

const ModalOverlay = (props) => {
  const [password, set_password] = useState('')
  const [allowCookies, setAllowCookies] = useState('')
  const {forbidCookieRef, noCookieRef, inputsRef, validRef, initialInputs, initialValid} = useContext(InputDataContext);

  const handleAllowCookies = () => {
    forbidCookieRef.current = false
  }

  const handleForbidCookies = () => {
    forbidCookieRef.current = true
    deleteCookie('inputs')
    deleteCookie('valids')
    inputsRef.current = initialInputs
    validRef.current = initialValid
    noCookieRef.current = true

  }


  return (
    <div className={classes.modal}>
      <h2>Before you start</h2>
      <p>
      For the best experience, use this tool on a laptop or desktop computer.
      </p>
      <p> 
      It’s important to have your Annual Benefit Statement (ABS) to hand if you can. This will make sure your 
      estimate is as accurate as it can be and means as many members as possible can use this tool.
      </p>
      <p>
      You can find your ABS by logging into ESR if you use it – your ABS forms part of your Total Reward Statement. 
      Or you can find your ABS by logging into <a href = "https://mynhspension.nhsbsa.nhs.uk/home/" target = "_blank"> My NHS Pension</a>.
      If you can’t access your ABS, it’s still possible to 
      use this tool to give you a general picture, but be aware the figures will be less accurate. 
      </p>

      <div className = {classes.cookie_div}> 
        <h3> Can we use cookies? </h3>
        <p> Can we use cookies to save the inputs you provide in this tool for up to 24 hours? 
          This will save you from entering them again if you close the tab and come back later. 
        </p>
        {!noCookieRef.current && <p> If you click No, we will delete the cookies that have already been saved from a previous visit.</p>}
        <div> 
          <GenericButton onClick = {() => setAllowCookies('Yes')} active = {allowCookies === 'Yes'}> Yes </GenericButton> 
          <GenericButton onClick = {() => setAllowCookies('No')} active = {allowCookies === 'No'}> No </GenericButton> 
        </div>
      </div>

      <p> To use this tool, enter the password below</p>
      <div className = {classes.password_and_button_container}> 
      <input 
      type = 'password' 
      value = {password}
      onChange = {(e) => set_password(e.target.value)}
      />
      {password === 'GAD001' && allowCookies !== '' &&
      <button
        className={`${classes.btn_main} ${
         classes.btn_agreed
        }`}
        onClick={
          () => {
            if(allowCookies === 'Yes') {
              handleAllowCookies()
            } else {
              handleForbidCookies()
            }
            props.onClick()
          }
        }
      >
        Continue to the Remedy Benefits Illustrator
      </button>
      }
      </div>
    </div>
  );
};

const TermsModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay onClick={props.onClick} />,
        document.getElementById("modal-root")
      )}
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
    </Fragment>
  );
};

export default TermsModal;
