import React, { Fragment, useState, useRef, useEffect } from "react";
import InfoModal from "../../general/InfoModal";
import { submitInputData } from "../../../shared/apis/mainAPI";
import { formatDate, setToStartOfDay } from "../../../utilities/Formatters";

import classes from "./RetirementAgeSwitch.module.css";

const RetirementAgeSwtich = (props) => {
  const [showModal, setShowModal] = useState(false);
  //If the user changes their DOB in the 'Input Reminder' box,
  //then the SPA will change, in which case it may no longer be valid to
  //reset to the retirement date that was chosen in the your details 
  //page, which we have  in inputsRef.current.stored_ret_date.
  //show_reset will increase by 1 every time this changes (plus it increases
  //by 1 in the useEffect when the component first mounts),
  //so we know that if it is above 1, the spa has changed and therefore we do not show the 
  //'show_reset' button.
  const [show_reset, set_show_reset] = useState(0)
  const [retirementAge, setRetirementAge] = useState(props.retirementAge) 
  const [retirementAgeForBackend, setRetirementAgeForBackend] = useState(retirementAge)
  const [text_retirement_age, set_text_retirement_age] = useState(retirementAge)
  const [legacy_warning, set_legacy_warning] = useState('')
  const [reformed_warning, set_reformed_warning] = useState('')
  const [text_focused, set_text_focused] = useState(false)
  const exact_age_rounded = props.userExactAgeRoundedDown
  const exact_age = props.userExactAge
  const max_ret_age = 75
  const max_ret_date = props.SPA_date
  const retirement_age_num = Number(retirementAge)
  const rounded_retirement_age = Math.round(retirement_age_num)

  const legacy_npa_date = props.legacy_npa_date
  const reformed_npa_date = props.reformed_npa_date
  let today = new Date()
  today = setToStartOfDay(today)

  function isNear(x,y) {
    const diff = x - y
    const absDiff = Math.abs(diff)
    return absDiff < 0.01
  }

  function roundProperly(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100
  }


  const handlePlus = () => {
    
    var new_ret_age
    
    
    if(retirement_age_num !== rounded_retirement_age) {
      new_ret_age = Math.min(max_ret_age, Math.ceil(retirement_age_num))
      
      const rounded_new_ret_age = Math.round(new_ret_age)
      if(rounded_new_ret_age !== new_ret_age) {
        new_ret_age = roundProperly(new_ret_age)
      }
    } else {
      new_ret_age = Math.min(max_ret_age, retirement_age_num + 1)

      const rounded_new_ret_age = Math.round(new_ret_age)
      if(rounded_new_ret_age !== new_ret_age) {

        new_ret_age = roundProperly(new_ret_age)
      }
    }
    
    setRetirementAgeForBackend(new_ret_age)
  }

  const handleMinus = () => {
    var new_ret_age
    
    if(retirement_age_num !== rounded_retirement_age) {
      new_ret_age = Math.max(exact_age_rounded, Math.floor(retirement_age_num))
      const rounded_new_ret_age = Math.round(new_ret_age)
      if(rounded_new_ret_age !== new_ret_age) {
        new_ret_age = Math.round(new_ret_age, 2)
      }
    } else {
      new_ret_age = Math.max(Math.max(exact_age, retirement_age_num - 1),55)
      const rounded_new_ret_age = Math.round(new_ret_age)
      if(rounded_new_ret_age !== new_ret_age) {
        new_ret_age = Math.round(new_ret_age, 2)
      }
    }
    setRetirementAgeForBackend(new_ret_age)
  }

  useEffect(
    () => {
      set_text_retirement_age(retirementAge)
    },
    [retirementAge]
  )

  useEffect(
    () =>{
      if(retirement_age_num > max_ret_age) {
        setRetirementAge(String(roundProperly(max_ret_age)))
      }
      set_show_reset(prev => prev+1)
    },
    [props.spa_months, props.spa_years, props.userExactAge]
  )

  var extra = null
  var extra2 = ''

  


    if (today.toDateString() === setToStartOfDay(new Date(props.retirementDate)).toDateString()) {
      var extra = <p className= {classes.immediate_ret}> *This means retiring immediately today</p>
      var extra2 = '*'
    } else if(retirement_age_num !== Math.round(retirement_age_num)) {
  
    if(props.retirementDate === props.SPA_date_string) {
      var text_append = ' which is the latest retirement age that this tool can handle.'
    } else {
      var text_append = " which is the retirement date chosen on the 'Your Details' page."
    }
      var extra = <p className= {classes.immediate_ret}>
         *This means retirement on {formatDate(props.retirementDate)},
         {text_append}
         </p>
      var extra2 = '*'
  }


  const initial_mount = useRef(true)

  function clean_retirement_string(str) {
    return str.replace(/[^0-9]/g, "")
  }

  const legacy_npa = props.legacy_npa
  const reformed_npa = roundProperly(props.reformed_npa)


  function changeHandler(event) {
    let cleaned = event.target.value
    cleaned = clean_retirement_string(cleaned)
    cleaned = Number(cleaned)
    cleaned = Math.min(max_ret_age, cleaned)
    cleaned = Math.max(0, cleaned)
    cleaned = roundProperly(cleaned)
    set_text_retirement_age(String(cleaned))
  }

  const handleReset = () => {
    const fetchData = async () => {
      props.inputsRef.current['retirementDate'] = props.inputsRef.current.stored_ret_date;
      try {
        const data = await submitInputData(
          {...props.inputsRef.current, 'retirementDate': props.inputsRef.current.stored_ret_date,
          //In this case, we want the submitInputData functon and the backend
          //to know what the backend should rely on the retirement date for calculations, 
          //and not the retirement age. So we specify 'not_ret_switch'.
          'coming_from' :'not_ret_switch'
          }
        );
        console.log('data received from backend', data)
        props.setOutputData(data);
        props.inputsRef.current['retirementAge'] = data['retirementAge']
        props.inputsRef.current['retirementDate'] = data['retirementDate']
        setRetirementAge(roundProperly(Number(data['retirementAge'])))
        //999 is a dummy value. We use this because we need `retirementAgeForBackend`
        //to change to something when we reset, so that when we change it again, the 
        //useEffect below will definitely be triggered.
        //Of course, this means that the useEffect below will be triggered when we 
        //set RetirementAgeForBackend to 999 - but don't worry, we have an `if` 
        //in that useEffect that ensures we won't call the backend again if 
        //retirementAgeForBackend is 999.
      
        setRetirementAgeForBackend(999)
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle any errors here
      }
    };

    // Call the async function
    fetchData();
  }

  const modalHandler = () => {
    setShowModal(!showModal);
  };



  useEffect(() => {
    //don't trigger the function if we are on the initial mount, if the 
    //text input is focused, or if the retirementAgeForBackend has been set to a dummy
    //value
    if (!initial_mount.current && !text_focused && retirementAgeForBackend !== 999) {
  
      // Define an async function inside useEffect
      const fetchData = async () => {
        props.inputsRef.current['retirementAge'] = retirementAgeForBackend;
        try {
          const data = await submitInputData(
            {...props.inputsRef.current, 'retirementAge': retirementAgeForBackend,
            //when we set 'coming_from' to 'ret_switch', it 
            //tells the submitInputData function that 
            //the retirement age, and not the retirement date,
            //is the thing that the backend should use in its calculations.
            //If we are adjusting the retirement date on the your details page,
            //we want the backend to know that it should base calculations
            //on the retirement date and not the retirement age
            'coming_from' :'ret_switch'
            }
          );
          console.log('data received from backend', data)
          props.setOutputData(data);
          props.inputsRef.current['retirementAge'] = data['retirementAge']
          props.inputsRef.current['retirementDate'] = data['retirementDate']
          setRetirementAge(data['retirementAge'])
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle any errors here
        }
      };
  
      // Call the async function
      fetchData();
    } else {
      initial_mount.current = false;
    }
  }, [retirementAgeForBackend, text_focused]);
  

  const infoMessage = (
    <Fragment>
      <h2>Adjust Retirement Age</h2>
      <p>
        You can use these buttons below to adjust your retirement age.
      </p>
    </Fragment>
  );


  return (
    <div className={classes.main_container + ' ' + classes.ret_age_container} >
      {showModal && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
      )}
      <button
        id="RetirementAgeSwitchHelp"
        className={classes.help_button}
        onClick={modalHandler}
      >
        Help
      </button>

      <h3>Adjust Retirement Age</h3>
      <div className = {classes.everything_except_title_container}> 
        <div className = {classes.reset_and_plus_minus_container}> 
          {(show_reset < 2) && <div className = {classes.reset_container}>
            <button 
            onClick = {handleReset}
            className = {classes.adjust_button}
            > Chosen Retirement Date </button>
            </div>}

            <div className={classes.radio_container + ' ' + classes.extra_container}> 
              <button onClick = {handleMinus}
                className = {classes.adjust_button}
              > - 
              </button>

              <input type = 'text' 
              value = {text_retirement_age + (text_focused ? '' : (' years old' + extra2))} 
              onChange = {changeHandler}
              onFocus = {
                () => {
                  set_text_focused(true)
                  if(Number(retirementAge) !== Math.floor(Number(retirementAge))) {
                    set_text_retirement_age(String(Math.floor(Number(retirementAge))))
                  } else {
                    set_text_retirement_age(String(retirementAge))
                  }
                }
              }
              onBlur = {
                () => {
                  set_text_focused(false)
                  setRetirementAgeForBackend(
                    Math.min(Math.max(Number(text_retirement_age), exact_age_rounded), max_ret_age)
                  )
                }
              }
              />
              {extra}
              <button onClick = {handlePlus}
              className = {classes.adjust_button}
              > + </button>
            </div>
        </div>
      
        <div className = {classes.npa_buttons_container}> 
          <button 
          className = {
            classes.adjust_button + ' ' +
            (exact_age > legacy_npa ? classes.greyed_out : '') + ' ' +
            (legacy_npa === retirement_age_num ? classes.active_button : '')
          }
          onClick = {() =>{
            if(legacy_npa_date >= today) {
              
              //this will always be a whole number, which is fine because the backend can handle 
              //changes to retirement date implemented by specifying a retirement age as long as hte 
              //retirement age is a whole number of a decimal containing only years and months.
              setRetirementAgeForBackend(legacy_npa)
            } 
          }
          }
          onMouseOver = {
            () => {
              if(today > legacy_npa_date) {
                set_legacy_warning("You can't set your retirement age to your legacy NPA because you are already older than your legacy NPA.")
              }
            }
          }
          onMouseLeave = {
            () => {set_legacy_warning('')}
          }
          > set to {props.legacySchemeName} NPA </button>
          
          <button 
          className = {classes.adjust_button + ' ' + (exact_age > reformed_npa ? classes.greyed_out : '') + ' ' +
          (reformed_npa === roundProperly(retirement_age_num) ? classes.active_button : '')}
          onClick = {() =>{
            if(reformed_npa_date >= today) {
              setRetirementAgeForBackend(reformed_npa)
            } 
          }
          }
          onMouseOver = {
            () => {
              if(today > reformed_npa_date) {
                set_reformed_warning("You can't set your retirement age to your reformed NPA because you are already older than your reformed NPA.")
              }
            }
          }
          onMouseLeave = {
            () => {set_reformed_warning('')}
          }
          > 
          set to 2015 Scheme NPA 
        </button>
      </div>
    </div>
         {legacy_warning !== '' && <p className = {classes.warning_message}> {legacy_warning}</p>}
         {reformed_warning !== '' && <p className = {classes.warning_message}s> {reformed_warning}</p>}
    </div>
  );
};

export default RetirementAgeSwtich;
