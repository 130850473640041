export const currencyFormatter = (value) => {
  return new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
    roundingMode: "halfExpand",
  }).format(value);
};

export const capitaliseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b);

export function ageToYearsAndMonths(age) {
  // Check if the age is an integer
  if (Number.isInteger(age)) {
      return `${age}`;
  }

  // Calculate the years and months
  const years = Math.floor(age);
  const months = Math.round((age - years) * 12);

  return `${years} years and ${months} months`;
}
