import React from 'react'
import classes from './GenericButton.module.css'

const GenericButton = (props) => {
    return(
        <button 
            className = {classes.button + ' ' + (props.active ? classes.active : '')} 
            onClick = {props.onClick}
        >
            {props.children}
        </button>
    )
}

export default GenericButton