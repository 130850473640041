import React, { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import SalaryDemo from "../../pages/SalaryDemo";

import classes from "./SalaryModal.module.css";

const Backdrop = (props) => {
  return <div className={classes.background} onClick={props.onClick} />;
};

const ModalOverlay = (props) => {
  const width =  '90vw'
  const top = props.top ? props.top : '2vh'
  const height = '96vh'
  return (
    <div className={classes.modal}
    style = {{'--width' : width, '--top' : top, '--height' : height, 'overflowY' : 'auto',
            'padding' : 0, 'left' : '5vw'
            }}
    >   
    <div style = {{'width' : '90%', 'marginLeft' : '5%', marginTop: '2vh'}}> 
      <SalaryDemo
      onClick={props.onClick}
      salaryCurrent = {props.salaryCurrent}
      inputsRef = {props.inputsRef}
      salaryIncrease = {props.salaryIncrease}
      />
    </div>
    </div>
  );
};

const SalaryModal = (props) => {
  useEffect(() => {
    const close = (event) => {
      if (event.keyCode === 27) {
        props.modalHandler();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  });

  

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay
          onClick={props.modalHandler}
          infoMessage={props.infoMessage}
          width = {props.width}
          top = {props.top}
          height = {props.height}
          salaryCurrent = {props.salaryCurrent}
          inputsRef = {props.inputsRef}
          salaryIncrease = {props.salaryIncrease}
        />,
        document.getElementById("modal-root")
      )}
      {ReactDOM.createPortal(
        <Backdrop onClick={props.modalHandler} />,
        document.getElementById("backdrop-root")
      )}
    </Fragment>
  );
};

export default SalaryModal;
