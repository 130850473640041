import React from "react"
import classes from "./MinorButton.module.css";

const MinorButton = (props) => {
  return (

        <button className={classes.minorButton} onClick={props.onClick}>
          {props.children}
        </button>
  );
};

export default MinorButton;
