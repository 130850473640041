import React, {useState, useRef, useEffect} from 'react'
import classes from './Card.module.css'
import {currencyFormatter} from '../../utilities/Formatters'
import generateSalaryData, { getFinancialYear, what_would_salaries_be_without_this_year } from '../../shared/utilities/InterpolateSalaries'



const Card = (props) => {
    const [value, set_value] = useState(props.selected)
    const [text_value, set_text_value] = useState(String(value))
    const [mouseDown, setMouseDown] = useState(false)
    const [text_focused, set_text_focused] = useState(false)
    const initial_mount = useRef(true)

    const handleChange = (event) => {
      let new_val = Number(event.target.value)
      new_val = Math.min(new_val, Number(props.max))
      new_val = Math.max(new_val, Number(props.min))
        set_value(new_val)
        setMouseDown(true)
    }

    function clean_lump_sum_string(str) {
      return str.replace(/[^0-9]/g, "")
    }

    function textChangeHandler(event) {
      const val = event.target.value
      const cleaned_as_string = clean_lump_sum_string(val)
      const cleaned_as_num = Math.min(200000,cleaned_as_string)
      const ret = String(cleaned_as_num)
        set_text_value(
          ret
        )
      }

    useEffect(() => {
      if(!text_focused && !mouseDown){
        set_text_value(value)
      }

      if (!initial_mount.current && !mouseDown && Number(value) !== props.selected) {
        //if value does equal props.selected, it would indicate that the value the user has chosen is
        //no different to what is already being displayed, so no need
        //to redo the same calculated. 
        props.onChange(Number(value))
      } else {
        initial_mount.current = false;
      }
    }, [value, mouseDown]);

    useEffect(
      () => {
        if(props.selected !== Number(value)) {
          set_value(props.selected)
        }
      },
      [props.selected]
    )
    
    return(
        <div className = {classes.main_container} style = {{'--this-color' : props.color, '--this-light-color' : props.light_color}}>
         
        <div className={classes.radio_container}>
        <h2> {props.title}</h2>
        <input
          type="range"
          min= {"0"}
          max= {"200000"}
          step="50"
          value={String(value)}
          onChange={handleChange}
          className="slider"
          onMouseUp={() => {
            setMouseDown(false);
          }}
          onKeyUp={() => {
           setMouseDown(false);
          }}
        />
        <input type = 'text' 
        value = {
          text_focused ? 
          String(Math.round(Number(text_value))) :
          currencyFormatter(String(Math.round(Number(text_value))))
        } 
        onChange = {textChangeHandler}
        onFocus = {() => set_text_focused(true)}
        onBlur = {
          () => {
            set_text_focused(false)
            if(Number(value) !== Number(text_value)) {
              let new_val = Number(text_value)
              new_val = Math.max(new_val, props.min)
              new_val = Math.min(new_val,  props.max)
              set_value(Number(text_value))
              //If this triggers a useEffect, I hope that text_focused will be false within the useEffect
            }
          }
        }
        />
        <button onClick = {
          () => {
            const financialYear = getFinancialYear()
            const initialSalaryCurrent = props.initialSalaryCurrentRef ? props.initialSalaryCurrentRef.current : null
            const updatedExplicitData = what_would_salaries_be_without_this_year(props.this_year, props.explicitData, initialSalaryCurrent)
            props.setExplicitData(updatedExplicitData)
            props.setChartData(generateSalaryData(updatedExplicitData, 2015, financialYear, props.SI ))
            }
          }> Reset </button>
      </div>
      
      </div>
    )
}


export default Card