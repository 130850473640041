//This function is used to generate the full salary history and trajectory for a member, from start_year to end_year,
//given the (at least one, possibly more) salary values they have explicitly provided, plus the salary increase
//they have specified.

export default function generateSalaryData(initialData, start_year, end_year, annual_salary_increase, include_non_remedy = false) {
    // First, create a map of year to salary for easier lookup
    const salaryMap = {};
    initialData.forEach(item => {
      salaryMap[item.year] = { salary: item.salary, basis: 'provided' };
    });
  
    const result = [];
  
    // Helper function to interpolate between two values
    function interpolateSalary(year, startYear, endYear, startSalary, endSalary) {
      const fraction = (year - startYear) / (endYear - startYear);
      return startSalary + fraction * (endSalary - startSalary);
    }
  
    // Sort initial data by year to get the earliest known year and salary
    initialData.sort((a, b) => a.year - b.year);
    const earliestKnownYear = initialData[0].year;
    const earliestKnownSalary = initialData[0].salary;
  
    let lastKnownYear = null;
    let lastKnownSalary = null;
    
    
    for (let year = start_year; year <= end_year; year++) {
      if(include_non_remedy) {
        var condition = true
      } else {
        condition = (year <=2022) || year === end_year
      }
      if(!condition) {
        result.push(
          {'year' : year, 'basis' : 'NA', 'salary' : null}
        )
        continue
      }
      if (salaryMap[year]) {
        // If the salary is provided, we use it
        result.push({ year, salary: salaryMap[year].salary, basis: salaryMap[year].basis });
        lastKnownYear = year;
        lastKnownSalary = salaryMap[year].salary;
      } else {
        if (year < earliestKnownYear) {
          // Extrapolate backwards from the earliest known salary
          const extrapolatedSalary = earliestKnownSalary / Math.pow(annual_salary_increase, earliestKnownYear - year);
          result.push({ year, salary: extrapolatedSalary, basis: 'extrapolated' });
        } else {
          // Find the next year in the input data
          const nextKnownYear = initialData.find(item => item.year > year);
          if (lastKnownYear && nextKnownYear) {
            // If we have both a previous and next known year, interpolate
            const interpolatedSalary = interpolateSalary(
              year,
              lastKnownYear,
              nextKnownYear.year,
              lastKnownSalary,
              nextKnownYear.salary
            );
            result.push({ year, salary: interpolatedSalary, basis: 'interpolated' });
          } else if (lastKnownSalary) {
            // If no next year is found, extrapolate using the last known salary
            const extrapolatedSalary = lastKnownSalary * Math.pow(annual_salary_increase, year - lastKnownYear);
            result.push({ year, salary: extrapolatedSalary, basis: 'extrapolated' });
          } else {
            // If there's no last known salary, just skip (though this case shouldn't happen given the input assumptions)
            continue;
          }
        }
      }
    }
  
    return result;
  }

export const updateOrAddSalary = (data, year, newSalary) => {
    // Check if there's an entry for the specified year
    const record = data.find(item => item.year === year);
    
    if (record) {
      // If found, update the salary
      record.salary = newSalary;
    } else {
      // If not found, add a new object
      data.push({ year, salary: newSalary });
    }
  
    // Return the updated array
    return data;
  };

export function getFinancialYear() {
  const today = new Date();
  const year = today.getFullYear();
  const aprilFirst = new Date(year, 3, 1); // April 1 of the current year

  // If today is before April 1, the financial year started in the previous year
  return today < aprilFirst ? year - 1 : year;
}

export const updateExplicitData = (explicitData, relevantYear, newSalary) => {
  const idx_in_explicit_data = explicitData.findIndex(obj => obj.year === relevantYear)

  var updatedData = [...explicitData];
  if(idx_in_explicit_data === -1) {
      updatedData.push(
      {'salary' : Math.max(1, newSalary), 'year' : relevantYear}
      )
  } else {
      updatedData[idx_in_explicit_data].salary = Math.max(1, newSalary); // Avoid negative salary
  }

  return updatedData
}

export function what_would_salaries_be_without_this_year(this_year, explicitData, initialSalaryCurrent) {
  const financialYear = getFinancialYear()
  if(this_year === financialYear) {
    const updatedExplicitData = updateExplicitData(explicitData, financialYear, initialSalaryCurrent)
    return updatedExplicitData
  }

  const updatedExplicitData = explicitData.filter((x) => x.year !== this_year)
  return updatedExplicitData
  
}