import React, { useState, useRef, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
} from "chart.js";

import generateSalaryData, { getFinancialYear } from "../../shared/utilities/InterpolateSalaries";
import annotationPlugin from 'chartjs-plugin-annotation';


// Register the necessary chart components
ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale, annotationPlugin);

const DraggableChart = (props) => {
  const chartRef = useRef(null);
  const chartContainerRef = useRef(null)
  const shades = props.shades
  const financialYear = getFinancialYear()


  const [draggingPoint, setDraggingPoint] = useState(null);

  useEffect(() => {
    const canvas = chartRef.current?.canvas;

    const handleMouseMove = (event) => {
      if (draggingPoint !== null && chartRef.current) {
        const chartInstance = chartRef.current;
        const yScale = chartInstance.scales.y;

        if (!yScale) return;

        const canvasPosition = canvas.getBoundingClientRect();

        // Get the mouse's position relative to the canvas
        const mouseY = event.clientY - canvasPosition.top;

        // Convert the Y position to the corresponding salary value on the chart
        const newYValue = Math.max(Math.min(yScale.getValueForPixel(mouseY), 200000), 0);
        const relevantYear = props.chartData[draggingPoint].year === 2023 ? financialYear : props.chartData[draggingPoint].year

        var updatedChartData = [...props.chartData]
        updatedChartData[draggingPoint].salary = Math.max(0, newYValue)
      
        props.setExplicitData(props.updateExplicitData(props.explicitData, relevantYear, newYValue))
        props.setChartData(updatedChartData)
      }
    };

    const handleMouseUp = () => {
      if(draggingPoint !== null) {
        setDraggingPoint(null); // Stop dragging when the mouse is released
        props.setChartData(generateSalaryData(props.explicitData, 2015, 2024, 1.03))
      }
    };

    if (canvas) {
      canvas.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (canvas) {
        canvas.removeEventListener("mousemove", handleMouseMove);
      }
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [draggingPoint, props.chartData]);

  const handleMouseDown = (event) => {
    const chartInstance = chartRef.current;

    if (chartInstance) {
      const elements = chartInstance.getElementsAtEventForMode(
        event,
        "nearest",
        { intersect: true },
        false
      );

      if (elements.length > 0) {
        const index = elements[0].index;
        setDraggingPoint(index); // Start dragging the selected point
      }
    }
  };

  const adjustedChartData = props.chartData.map((point) => ({
    ...point,
    year: point.year > 2022 ? 2023 : point.year,
  })).filter((point) => point.salary !== null);
  console.log(adjustedChartData)

  const data = {
    labels: adjustedChartData.map((point) => point.year),
    datasets: [
      {
        label: "Salary",
        data: adjustedChartData.map((point) => point.salary),
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: "rgba(75,192,192,0.2)",
        pointBackgroundColor: adjustedChartData.map((point) =>
          props.colour_mapper[point.basis]
        ),
        pointBorderColor: adjustedChartData.map((point) =>
          props.colour_mapper[point.basis]
        ),
        pointRadius: 8,
        pointHoverRadius: 10,
        fill: false,
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio : false,
    scales: {
      x: {
        type: 'linear',
        min: 2015,
        max: 2023,
        title: {
          display: true,
          text: "Year",
          font: {
            size: 16, // Increase font size of x-axis label
          },
        },
        ticks: {
          font: {
            size: 14, // Increase font size of x-axis numbers
          },
          callback: function (value) {
            if (value === 2023) {
              return 'Current';
            } else {
              return String(value);
            }
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Salary",
          font: {
            size: 16, // Increase font size of y-axis label
          },
        },
        ticks: {
          font: {
            size: 14, // Increase font size of y-axis numbers
          },
          callback: function (value) {
            return '£' + value.toLocaleString(); // Format y-axis numbers as £
          },
        },
        min: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          // Customize the tooltip title
          title: function (context) {
            const xValue = context[0].parsed.x;
            return xValue === 2023 ? 'Current' : String(xValue);
          },
          // Customize the tooltip label
          label: function (context) {
            const yValue = Math.round(context.parsed.y);
            return 'Salary: £' + yValue.toLocaleString();
          },
        },
      },
      annotation: {
        annotations: {
          shadedArea1: {
            type: 'box',
            xMin: 2015, // Start year of the rectangle
            xMax: 2022.5, // End year of the rectangle
            yMin: 0, // Minimum salary value
            yMax: 200000, // Maximum salary value
            backgroundColor: shades['a'], // Shaded area color
            borderWidth: 0,
          },
          shadedArea2: {
            type: 'box',
            xMin: 2022.5, // Start year of the rectangle
            xMax: 2023, // End year of the rectangle
            yMin: 0, // Minimum salary value
            yMax: 200000, // Maximum salary value
            backgroundColor: shades['b'], // Shaded area color
            borderWidth: 0,
          },
        },
      },
    },
    onHover: (event, chartElements) => {
      // Change cursor to pointer when hovering over points
      if (chartElements.length > 0) {
        event.native.target.style.cursor = "pointer";
      } else {
        event.native.target.style.cursor = "default";
      }
    },
  };

  return (
    <div
      style={{ position: "relative"}}
      onMouseDown={handleMouseDown} // Attach onMouseDown to the canvas
      ref = {chartContainerRef}
    >
      <Line ref={chartRef} data={data} options={chartOptions} height = {chartContainerRef.current ? chartContainerRef.current.style.height : 250}/>

    </div>

  );
};

export default DraggableChart;
