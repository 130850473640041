import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import PensionBarChart from "../components/results/charts/PensionBarChart";
import LumpSumBarChart from "../components/results/charts/LumpSumBarChart";
import RetirementAgeWarning from "../components/results/RetirementAgeWarning";
import RetirementAgeSwitch from "../components/results/switches/RetirementAgeSwitch";
import InputReminder from "../components/results/switches/InputReminder";
import LumpSumSwitch from "../components/results/switches/LumpSumSwitch";
import SalarySlider from "../components/results/switches/SalarySlider";
import ComparisonTable from "../components/results/ComparisonTable";
import DownloadPdfButton from "../components/results/download/DownloadButton";
import ShowAs from "../components/results/switches/ShowAs";
import TableMainResults from "../components/results/charts/TableMainResults";
import { moveDateByYears, getLatestDate } from "../utilities/Formatters";
import SalaryCheckingTable from "../components/results/SalaryCheckingTable"


import { OutputDataContext } from "../App";
import { InputDataContext } from "../shared/contexts/InputDataContext";

import classes from "./3-ShowDetail.module.css";

function ShowDetail() {
  const show_checks = window.show_checks_development && (window.HOST_URL.includes("gad-hosting") || window.HOST_URL.includes("localhost"))
  const { inputsRef} = useContext(InputDataContext);
  const { outputDataRef } = useContext(OutputDataContext);
  const [outputData, setOutputData] = useState(outputDataRef.current)
  console.log('outputData: ', outputData)
  const [as_chart, set_as_chart] = useState(true)
  const [show_options, set_show_options] = useState(false)
  const saved_scenarios_ref = useRef({})

  const userExactAge = outputData['exact_age']
  const userExactAgeRoundedDown = Math.floor(userExactAge)
  const legacySchemeName = inputsRef.current.legacyScheme

  const legacy_npa = outputData.legacy_npa
  const spa_years = outputData.spa_age['years']
  const spa_months = outputData.spa_age['months']
  const SPA_date = new Date(outputData.spa)
  
  const legacy_npa_date = moveDateByYears(new Date(outputData.DOB), legacy_npa)
  
  const reformed_npa_date = new Date(outputData.reformed_npa_date)

  const remedySchemeName = '2015 Scheme'
  const message = <p> All numbers are in nominal terms</p>
  const accrual_rates = outputData['accrual_rate']
  const accrual_rate_choose_reformed = accrual_rates['2015-04-01']
  const accrual_rate_choose_legacy = accrual_rates['2022-04-01']
       

  useEffect(() => {
    // Set the body margin to 0px when the ResultsPage component mounts
    document.body.style.width = '90%';

    // Cleanup function to reset the body margin when the component unmounts or user navigates away
    return () => {
      document.body.style.width = '75%'; // Reset to the default margin or desired value
    };
  }, []);




  useEffect(
    () => outputDataRef.current = outputData, [outputData]
  )


  return (
    <div id="Results_Page" className = {classes.results}
    >

      <h1 style = {{'fontSize': '1.6rem', 'textAlign': 'center'}}>A detailed view of your options</h1>                

        <p style = {{'fontSize' : '1rem'}}>
          This page illustrates your options in more detail – it explains how much each separate part of your pension is worth, and you can use 
          the tools to see how different decisions affect your benefits. 
        </p>
        <InputReminder
          setOutputData = {setOutputData}
        />

      

          <div className = {classes.slider_container}>
            <div className = {classes.slider}
            style = {{
              'transform' : 'translateX(' + (as_chart  ? '0' : '-50')+ '%)'
            }}
            > 
              <div className={classes.chart_container}>
                <div className={classes.chart} id = {'pension_chart'}>
                  <PensionBarChart
                    key={2}
                    dummy_data = {outputData['pension']}
                    retirementAge={inputsRef.current.retirementAge}
                    legacySchemeName={legacySchemeName}
                    remedySchemeName={remedySchemeName}
                    adjustInflationState={inputsRef.current.inflation}
                  />
                </div>

                <div className={classes.chart} id = {'lumpsum_chart'}>
                  <LumpSumBarChart
                    key={2}
                    dummy_data = {outputData['lump sum']}
                    retirementAge={inputsRef.current.retirementAge}
                    scheme={legacySchemeName}
                    adjustInflationState={inputsRef.current.inflation}
                    
                  />
                </div>
                <RetirementAgeWarning
                legacy_npa = {legacy_npa}
                legacy_npa_date = {legacy_npa_date}
                reformed_npa_date = {reformed_npa_date}
                retirementDate = {outputData.retirementDate}
                legacySchemeName = {legacySchemeName}
                spa_years = {spa_years}
                spa_months = {spa_months}
                tax_threshold_exceeded_care = {outputData.tax_threshold_exceeded_care}
                tax_threshold_exceeded_legacy = {outputData.tax_threshold_exceeded_legacy}
                />

              </div>
              <div className = {classes.table_container}> 
              <TableMainResults
              legacySchemeName={legacySchemeName}
              remedySchemeName={remedySchemeName}
              pension = {outputData.pension}
              lump_sum = {outputData['lump sum']}
              /> 
              <RetirementAgeWarning
                legacy_npa_date = {legacy_npa_date}
                reformed_npa_date = {reformed_npa_date}
                retirementDate = {outputData.retirementDate}
                legacySchemeName = {legacySchemeName}
                spa_years = {spa_years}
                spa_months = {spa_months}
                tax_threshold_exceeded_care = {outputData.tax_threshold_exceeded_care}
                tax_threshold_exceeded_legacy = {outputData.tax_threshold_exceeded_legacy}
                />
              </div>
              
            </div>
          </div>

      <br></br>
      <h3 style = {{'fontSize' : '1.3rem'}}> Use these tools to help you understand how different choices affect your benefits overall</h3>
      <div className = {classes.ret_switch_and_warning_container}> 

      <LumpSumSwitch
          setOutputData = {setOutputData}
          outputData = {outputData}
          inputsRef = {inputsRef}
          equalised_commutation_rate = {outputDataRef.current.equalised_commutation_rate}
          schemeName = {legacySchemeName}
        />
        <div className = {classes.ret_age_and_show_as_container}> 
          <RetirementAgeSwitch
            setOutputData = {setOutputData}
            inputsRef = {inputsRef}
            userExactAgeRoundedDown = {userExactAgeRoundedDown}
            userExactAge = {userExactAge}
            retirementAge = {outputData.retirementAge}
            retirementDate = {outputData.retirementDate}
            legacy_npa = {outputData.legacy_npa}
            reformed_npa = {outputData.reformed_npa}
            legacy_npa_date = {legacy_npa_date}
            reformed_npa_date = {reformed_npa_date}
            spa_years= {spa_years}
            spa_months ={spa_months}
            SPA_date = {SPA_date}
            SPA_date_string = {outputData.spa}
            legacySchemeName = {legacySchemeName}
          />

          
          
          <ShowAs 
          as_chart = {as_chart} set_as_chart = {set_as_chart}
          set_show_options = {set_show_options}
          show_options = {show_options}
          />
        </div>

      </div>

      <div className={classes.switch_container}
          // style = {
          //   (show_options) ? {} : {
          //     'margin' : 0, 
          //     'opacity' : 0,
          //     'maxHeight' : 0, 
          //     'pointerEvents' : 'none'
          //   }
          // }
      > 

      
      <SalarySlider

        legacySchemeName={legacySchemeName}
        setOutputData = {setOutputData}
        inputsRef = {inputsRef}
        salaryIncrease = {outputData.salaryIncrease}
      />
    </div>
      
      <ComparisonTable
        legacySchemeName={legacySchemeName}
        remedySchemeName={remedySchemeName}
        adjustInflation={inputsRef.current.inflation}
        pension = {outputData['pension']}
        lump_sum = {outputData['lump sum']}
        outputData = {outputData}
        saved_scenarios_ref = {saved_scenarios_ref}
      />

    {/* <DownloadPdfButton
    legacySchemeName={legacySchemeName}
    remedySchemeName={remedySchemeName}
    adjustInflation={inputsRef.current.inflation}
    pension = {outputData['pension']}
    lump_sum = {outputData['lump sum']}
    outputData = {outputData}
    saved_scenarios_ref = {saved_scenarios_ref}
    inputsRef = {inputsRef}
    /> */}
    {show_checks
    && <Fragment> 
    <h1> Check salary and CARE calcs for choose legacy </h1>
    {message}
    <SalaryCheckingTable jsonData = {outputData['choose legacy check']}/>
    <h1> Check salary and CARE calcs for choose reformed </h1>
    {message}
    <SalaryCheckingTable jsonData = {outputData['choose reformed check']}/>

      <h1> Other details</h1>
      <h3> Legacy scheme accrual rate</h3>
      <p> Usually, the accrual rate will be the standard accrual rate for the legacy scheme. However, for MHO and special class
        members in the 1995 scheme, there may be an adjusted accrual rate taking into account foregone double accrual. </p>
      <p> Accrual rate choose reformed: {accrual_rate_choose_reformed}</p>
      <p> Accrual rate choose legacy: {accrual_rate_choose_legacy}</p>

      <h3> Retirement factors (option B only - in option A may have been adjusted, in which case see below)</h3>

      <p> Legacy scheme pension factor: {outputData['ERF legacy']} </p>
      <p> Legacy scheme lump sum factor: {outputData['ERF legacy ls']} </p>
      <p> 2015 scheme factor: {outputData['ERF care']}</p>

      <h3> Final salary</h3>
      <p> The final salary used for the purposes of calculating legacy benefits is {outputData['salary_at_retirement']}</p>

      <h3> Service </h3>

      <p> Service choose reformed: {outputData['used_service_choose_reformed']}</p>
      <p> Service choose legacy: {outputData['used_service_choose_legacy']}</p>

      <h3> Adjustments to LRF to ensure no LRF applied to service accrued over age 65</h3>
      {legacySchemeName === '1995 Section' && <p> Not relevant for 1995 Section member</p>}
      {legacySchemeName === '2008 Section' && 
          (!outputData['did_adjust'] ? <p> No service over age 65  or no LRF</p> : 
          <div> 
            <p> Service accrued after age 65 in option A: {outputData['service_age_65_to_end_legacy']['2022-04-01']}</p>
            <p> Total service option A: {outputData['total_service']['2022-04-01']}</p>
            <p> Fraction of service accrued after age 65 option A: {outputData['frac']['2022-04-01']}</p>
            <p> 1 - Fraction of service accrued after age 65 option A (called 'adjuster' below): {outputData['adjuster']['2022-04-01']}</p>
            <p> Pension LRF in option A before adjustment: {outputData['pre_adjusted_pen_ret_factor']['2022-04-01']}</p>
            <p> Pension LRF in option A, minus 1, then multiplied by 'adjuster', then added to 1 again: {outputData['adjusted_pen_lrf']['2022-04-01']}</p>
            <p> Pension LRF in option A before adjustment: {outputData['pre_adjusted_ls_ret_factor']['2022-04-01']}</p>
            <p> Pension LRF in option A, minus 1, then multiplied by 'adjuster', then added to 1 again: {outputData['adjusted_ls_lrf']['2022-04-01']}</p>

          </div>
        )
      }


    </Fragment>



          }

    

    </div>
  );
}

export default ShowDetail;
